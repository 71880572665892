export default defineNuxtPlugin(() => {
  const textData = {
    save: '保存',
    overwriteSave: '上書きして保存',
    saveAs: '別名で保存',
    cancel: 'キャンセル',
    finish: '終了する',
    destruct: '破棄する',
    editFinishTitle: '編集を終了しますか？',
    editFinishText:
      '最新の状態で保存されていない場合はそれまでに修正された内容は破棄されますがよろしいですか？',
    editDestructTitle: '変更内容を破棄',
    editDestructText: '変更を破棄して最初の状態に戻っても宜しいですか？',
    editSaveTitle: '動画保存',
    editSaveText: '編集した内容やマーカーを保存します。',
    expandShrinkTitle: '拡大縮小',
    errorUploaded: 'アップロードされた動画は再生できません。'
  }
  return {
    provide: {
      text() {
        return textData
      }
    }
  }
})
