import { type Ref, reactive } from 'vue'
import { fabric } from 'fabric'
import {
  VIDEO_PLAYER_MODE,
  PLAY_MODE,
  type VideoPlayerState
} from '@/types/videoPlayer'
import { MARKER_COLORS, MARKER_TOOLS, type Point } from '@/types/marker'
import {
  drawText,
  drawing,
  getFabricItemByName,
  setAllObjectsEditable,
  refreshRulerPadding
} from '@/utils/marker'
import { isIOS } from '@/utils/common'

import IMG_REMOVE from '@/assets/images/icons/controller_remove.svg'
import IMG_ROTATE from '@/assets/images/icons/controller_rotate.svg'
import videojs from 'video.js'
import {
  normalErrorDialog,
  useShowGlobalLoading,
  useVideoAnalyseEnd,
  useVideoLoading
} from '~/composables/common'

const initState: VideoPlayerState = {
  url: '',
  screenshot: '',
  duration: 0,
  currentTime: 0,
  playbackRate: 1,
  mode: VIDEO_PLAYER_MODE.NORMAL,
  playmode: PLAY_MODE.NORMAL,
  playmodeConfig: {
    pointSetTime: 0,
    pointLogicCurrentTime: 0,
    pointPendingTime: {
      before: 0,
      after: 0
    },
    pointIntervalId: -1,
    pointIsPaused: true,
    lengthAnotherDuration: -1
  },
  volume: -1,
  transparency: 1,
  timeDesignation: {
    start: 0,
    end: 0
  },
  css: {
    overlay: {
      opacity: 1,
      clipWidth: 0,
      clipHeight: 0,
      translateX: 0,
      translateY: 0,
      scaleX: 1,
      scaleY: 1
    },
    marker: {
      zoom: 1,
      width: -1,
      height: -1
    },
    clientWidth: 0,
    clientHeight: 0,
    clientWidthInitial: 0,
    clientHeightInitial: 0,
    translateX: 0,
    translateY: 0,
    scaleX: 1,
    scaleY: 1
  },
  isCanplay: false,
  isCanShow: false,
  isReversal: false,
  isLoop: false,
  isGrid: false,
  isPaused: true,
  isMuted: false,
  marker: {
    tool: MARKER_TOOLS.EDIT,
    color: MARKER_COLORS.YELLOW2,
    lineWidth: 4,
    history: {
      list: [],
      listBackup: [],
      cursor: 0,
      cursorMin: 0
    }
  },
  controller: null
}

const PLAYBACK_RATE_MIN_BY_BROWSER_LIMIT = 0.0625

export const selectable = () => {
  const disabledExtension: Ref<boolean> = useState(
    'disabledExtension',
    () => false
  )
  return {
    disabledExtension
  }
}

export const playerData = () => {
  const url1: Ref<string> = useState('url1', () => '')
  const url2: Ref<string> = useState('url2', () => '')
  const uploadedVideo: Ref<string> = useState('uploadedVideo', () => '')
  const isComparisonMode: Ref<boolean> = useState(
    'isComparisonMode',
    () => false
  )
  const isErrorUpload: Ref<boolean> = useState('isErrorUpload', () => false)
  return {
    url1,
    url2,
    uploadedVideo,
    isComparisonMode,
    isErrorUpload
  }
}

export const playerDataForSingle = () => {
  const url: Ref<string> = useState('url', () => '')
  const uploadedVideoForSingle: Ref<string> = useState(
    'uploadedVideoForSingle',
    () => ''
  )
  const isComparisonModeForSingle: Ref<boolean> = useState(
    'isComparisonModeForSingle',
    () => false
  )
  const isErrorUploadForSingle: Ref<boolean> = useState(
    'isErrorUploadForSingle',
    () => false
  )
  return {
    url,
    uploadedVideoForSingle,
    isComparisonModeForSingle,
    isErrorUploadForSingle
  }
}

export const fullScreen = () => {
  const isFullScreen: Ref<boolean> = useState('isFullScreen', () => false)
  return {
    isFullScreen
  }
}

export const fullScreenForSingle = () => {
  const isFullScreenForSingle: Ref<boolean> = useState(
    'isFullScreenForSingle',
    () => false
  )
  return {
    isFullScreenForSingle
  }
}

// メインプレイヤー
export const stateVideoPlayer1 = reactive<VideoPlayerState>(
  JSON.parse(JSON.stringify(initState))
)
// 比較用プレイヤー
export const stateVideoPlayer2 = reactive<VideoPlayerState>(
  JSON.parse(JSON.stringify(initState))
)

export const stateVideoPlayerForSingle = reactive<VideoPlayerState>(
  JSON.parse(JSON.stringify(initState))
)

export const resetVideo1 = () => {
  const { url1, isComparisonMode } = playerData()
  url1.value = ''
  isComparisonMode.value = false
  stateVideoPlayer1.controller?.initData()
}

export const resetVideo2 = () => {
  const { url2, isComparisonMode } = playerData()
  url2.value = ''
  isComparisonMode.value = false
  stateVideoPlayer2.controller?.initData()
}

export const resetVideoForSingle = () => {
  const { url, isComparisonModeForSingle } = playerDataForSingle()
  url.value = ''
  isComparisonModeForSingle.value = false
  stateVideoPlayerForSingle.controller?.initData()
}

export const useVideoPlayer = (
  vState: VideoPlayerState,
  vStateOther: VideoPlayerState,
  vRef: Ref<HTMLVideoElement>,
  url: string,
  cRef: Ref<HTMLCanvasElement>,
  // TODO: 次Phase以降からPlayerMode追加
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mode: VIDEO_PLAYER_MODE = VIDEO_PLAYER_MODE.NORMAL
) => {
  vState.url = url
  let fb: fabric.Canvas
  let drawingObject: fabric.Object | null
  // let drawingTextbox: fabric.Textbox
  let isDrawing = false
  const mouseFrom: Point = { x: -1, y: -1 }
  const mouseTo: Point = { x: -1, y: -1 }

  let elementSelectionControlRemove: HTMLImageElement
  let elementSelectionControlRotate: HTMLImageElement
  let timeoutId: any = null
  let retryTimes: number = 0

  const videoResizeObserver = new ResizeObserver(() => {
    console.log('videoResizeObserver')
    console.log(vRef.value.clientWidth)
    console.log(vRef.value.clientHeight)
    if (vRef.value.clientWidth === 0 || vRef.value.clientHeight === 0) {
      return
    }
    vState.css.clientWidth = vRef.value.clientWidth
    vState.css.clientHeight = vRef.value.clientHeight
    if (
      !fb &&
      vState.css.clientWidthInitial === 0 &&
      vState.css.clientHeightInitial === 0
    ) {
      if (vState.css.marker.width === -1) {
        vState.css.marker.width = vRef.value.clientWidth
      }
      if (vState.css.marker.height === -1) {
        vState.css.marker.height = vRef.value.clientHeight
      }

      vState.css.clientWidthInitial = vRef.value.clientWidth
      vState.css.clientHeightInitial = vRef.value.clientHeight
      initFabric()
    }
    if (fb) {
      resizeMarker()
      refreshFabricZoom(fb)
    }
  })

  const getPlaybackRateLengthMode = (
    duration: number,
    anotherDuration: number
  ) => {
    if (anotherDuration < 0 || duration >= anotherDuration) {
      // if (
      //   duration >= anotherDuration &&
      //   vState.playbackRate * (anotherDuration / duration) <
      //     PLAYBACK_RATE_MIN_BY_BROWSER_LIMIT
      // ) {
      //   return PLAYBACK_RATE_MIN_BY_BROWSER_LIMIT / (anotherDuration / duration)
      // } else {
      //   return 1
      // }
      return 1
    } else {
      return duration / anotherDuration
    }
  }

  const setPlaybackRateFixed = () => {
    if (
      vState.playmode === PLAY_MODE.LENGTH &&
      vState.playmodeConfig.lengthAnotherDuration > 0
    ) {
      const playbackRateLengthMode = getPlaybackRateLengthMode(
        vState.timeDesignation.end - vState.timeDesignation.start,
        vState.playmodeConfig.lengthAnotherDuration
      )
      vRef.value.playbackRate = Math.max(
        PLAYBACK_RATE_MIN_BY_BROWSER_LIMIT,
        vState.playbackRate * playbackRateLengthMode
      )
    } else {
      vRef.value.playbackRate = vState.playbackRate
    }
  }

  const handleCanplay = () => {
    console.log('useVideoPlayer: canplay')
    console.log(`duration: ${vRef.value.duration}`)
    console.log(`currentTime: ${vRef.value.currentTime}`)
    vState.isCanplay = true
  }

  const handlelLoadedmetadata = () => {
    console.log('useVideoPlayer: loadedmetadata')
    console.log(`duration: ${vRef.value.duration}`)
    console.log(`currentTime: ${vRef.value.currentTime}`)
    console.log(`currentTime(vState): ${vState.currentTime}`)

    if (vState.playmode === PLAY_MODE.POINT) {
      vState.controller?.setPlayModePointCurrentTimeLogicOnly(
        vState.currentTime
      )
    } else {
      vRef.value.currentTime = vState.currentTime
    }
  }

  const handleLoadeddata = () => {
    if (!isFinite(vRef.value.duration)) {
      const cacheCurrentTime = toRaw(vRef.value.currentTime)
      const handleInfinityDuration = () => {
        vState.duration = vRef.value.duration
        vRef.value.currentTime = cacheCurrentTime
      }
      vRef.value.currentTime = 1e101
      setTimeout(() => {
        handleInfinityDuration()
      }, 50)
    }

    console.log('useVideoPlayer: loadeddata')
    console.log(`duration: ${vRef.value.duration}`)
    console.log(`currentTime: ${vRef.value.currentTime}`)
    console.log(`currentTime(vState): ${vState.currentTime}`)

    setPlaybackRateFixed()

    if (vRef.value.duration) {
      if (vState.timeDesignation.end === 0) {
        vState.timeDesignation.end = vRef.value.duration
      }
      vState.duration = vRef.value.duration

      if (
        vState.currentTime > 0 &&
        vRef.value.currentTime !== vState.currentTime
      ) {
        vRef.value.currentTime = vState.currentTime
      } else {
        vState.isCanShow = true
      }
    }
    vRef.value.addEventListener('durationchange', handleDurationchange)

    // スクショ
    // const canvas = document.createElement('canvas')
    // const ctx = canvas.getContext('2d')
    // canvas.width = vRef.value.clientWidth
    // canvas.height = vRef.value.clientHeight
    // const getUrl = async (): Promise<string> => {
    //   return await new Promise((resolve) => {
    //     ctx!.drawImage(
    //       vRef.value,
    //       0,
    //       0,
    //       vRef.value.clientWidth,
    //       vRef.value.clientHeight
    //     )
    //     canvas.toBlob((blob) => {
    //       const url = URL.createObjectURL(blob!)
    //       resolve(url)
    //     })
    //   })
    // }
    // vState.screenshot = await getUrl()
    useShowGlobalLoading().value = false
  }
  const handleDurationchange = () => {
    console.log('useVideoPlayer: durationchange')
    console.log(`duration: ${vRef.value.duration}`)
    console.log(`currentTime: ${vRef.value.currentTime}`)
    if (vRef.value.duration) {
      if (vState.timeDesignation.end === 0) {
        vState.timeDesignation.end = vRef.value.duration
      }
      if (vState.timeDesignation.end > vRef.value.duration) {
        vState.timeDesignation.end = vRef.value.duration
      }
      vState.duration = vRef.value.duration
    }
  }
  const handleTimeupdate = () => {
    retryTimes = 0
    if (
      vState.playmode === PLAY_MODE.NORMAL ||
      vState.playmode === PLAY_MODE.LENGTH
    ) {
      vState.currentTime = vRef.value.currentTime
      if (
        vRef.value.currentTime > vState.timeDesignation.end &&
        vRef.value.currentTime !== vRef.value.duration
      ) {
        if (vState.isLoop) {
          vRef.value.currentTime = vState.timeDesignation.start
        } else {
          vRef.value.currentTime = vState.timeDesignation.end
          vRef.value.pause()
        }
      }
    }
  }
  const handlePause = () => {
    console.log('useVideoPlayer: pause')
    console.log(`duration: ${vRef.value.duration}`)
    console.log(`currentTime: ${vRef.value.currentTime}`)
    vState.isPaused = true

    if (
      vState.playmode === PLAY_MODE.NORMAL ||
      vState.playmode === PLAY_MODE.LENGTH
    ) {
      if (vRef.value.duration === vRef.value.currentTime && vState.isLoop) {
        vRef.value.currentTime = vState.timeDesignation.start
        setTimeout(function () {
          vRef.value.play().catch((e) => console.log(e))
        }, 100)
      }
    }
  }
  const handlePlay = () => {
    vState.isPaused = false

    if (
      vState.playmode === PLAY_MODE.NORMAL ||
      vState.playmode === PLAY_MODE.LENGTH
    ) {
      console.log(vState.currentTime)
      console.log(vState.timeDesignation.end)

      if (vState.timeDesignation.end - vState.currentTime < 0.02) {
        vRef.value.currentTime = vState.timeDesignation.start
        vRef.value.play().catch((e) => console.log(e))
      }
    }
  }
  const handleSeeking = () => {
    retryTimes = 0
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      useShowGlobalLoading().value = false
    }, 30 * 1000)
    useVideoLoading().value = true
  }
  const handleSeeked = () => {
    retryTimes = 0
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    if (vRef.value.currentTime > 0) {
      vState.isCanShow = true
    }
    useVideoLoading().value = false
  }
  const handleRatechange = () => {
    console.log('useVideoPlayer: ratechange')
    console.log(`duration: ${vRef.value.duration}`)
    console.log(`currentTime: ${vRef.value.currentTime}`)
  }
  // !!!!! [not working in safari]
  // const handleVolumechange = () => {
  //   console.log('useVideoPlayer: onvolumechange')
  //   console.log(`duration: ${vRef.value.duration}`)
  //   console.log(`currentTime: ${vRef.value.currentTime}`)
  //   if (vRef.value.volume > 0) {
  //     vRef.value.muted = false
  //     vState.isMuted = false
  //   } else {
  //     vRef.value.muted = true
  //     vState.isMuted = true
  //   }
  // }
  const handleEnded = () => {
    console.log('useVideoPlayer: ended')
    console.log(`duration: ${vRef.value.duration}`)
    console.log(`currentTime: ${vRef.value.currentTime}`)
    useVideoAnalyseEnd().value = true
  }
  const handleError = () => {
    console.log('useVideoPlayer: error')
    console.log(`duration: ${vRef.value.duration}`)
    console.log(`currentTime: ${vRef.value.currentTime}`)
    const { isErrorUpload, uploadedVideo } = playerData()
    const { isErrorUploadForSingle, uploadedVideoForSingle } =
      playerDataForSingle()
    isErrorUpload.value = true
    isErrorUploadForSingle.value = true
    if (uploadedVideo.value === 'video1') {
      resetVideo1()
    } else if (uploadedVideo.value === 'video2') {
      resetVideo2()
    } else if (uploadedVideoForSingle.value === 'single') {
      resetVideoForSingle()
    }
    normalErrorDialog()
  }

  const refreshFabricZoom = (fb: fabric.Canvas) => {
    fb.setZoom(vState.css.marker.zoom)
    fb.setWidth(vState.css.marker.width)
    fb.setHeight(vState.css.marker.height)

    refreshRulerPadding(fb, vState.css.marker.zoom)
  }

  const refreshFabric = (fb: fabric.Canvas) => {
    fb.clear().renderAll()
    if (vState.marker.history.list.length > 0) {
      fb?.loadFromJSON(
        JSON.parse(
          vState.marker.history.list[vState.marker.history.list.length - 1].item
        ),
        () => {}
      )
      if (
        vState.marker.history.list[vState.marker.history.list.length - 1]
          .isReversal !== vState.isReversal
      ) {
        fbReversal()
      }
      fb?.renderAll()
    }
  }

  const getFabricJsonStrNow = (fb: fabric.Canvas): string => {
    return JSON.stringify(
      fb.toJSON([
        'name',
        'data',
        'padding',
        // 'borderColor',
        // '_controlsVisibility',
        'hasBorders',
        'hasControls',
        'selectable',
        'lockMovementX',
        'lockMovementY',
        'lockScalingX',
        'lockScalingY'
      ])
    )
  }

  const addFabricHistory = (fb: fabric.Canvas, date = 0) => {
    console.log('addFabricHistory')
    const jsonStrNew = getFabricJsonStrNow(fb)
    const itemLast =
      vState.marker.history.list[vState.marker.history.list.length - 1]
    if (
      vState.marker.history.list.length === 0 ||
      itemLast.item !== jsonStrNew
    ) {
      console.log(
        vState.marker.history.list.length > 0 ? itemLast.item : 'INIT'
      )
      console.log(jsonStrNew)
      vState.marker.history.list.push({
        ts: date === 0 ? Date.now() : date,
        isReversal: vState.isReversal,
        item: jsonStrNew
      })
      vState.marker.history.listBackup.splice(0)
      vStateOther.marker.history.listBackup.splice(0)
    }
  }

  const setFabricHistoryBack = (fb: fabric.Canvas) => {
    console.log('setFabricHistoryBack')
    console.log(vState.marker.history.list)
    console.log(vState.marker.history.listBackup)
    if (vState.marker.history.list.length > 0) {
      const item = vState.marker.history.list.pop()
      vState.marker.history.listBackup.push(item!)
      refreshFabric(fb)
      console.log(vState.marker.history.list)
      console.log(vState.marker.history.listBackup)
    }
  }

  const setFabricHistoryForward = (fb: fabric.Canvas) => {
    console.log('setFabricHistoryForward')
    console.log(vState.marker.history.list)
    console.log(vState.marker.history.listBackup)
    if (vState.marker.history.listBackup.length > 0) {
      const item = vState.marker.history.listBackup.pop()
      vState.marker.history.list.push(item!)
      refreshFabric(fb)
      console.log(vState.marker.history.list)
      console.log(vState.marker.history.listBackup)
    }
  }

  const initFabric = () => {
    console.log('initFabric')
    console.log(vState.css.clientWidthInitial)
    console.log(vState.css.clientHeightInitial)
    // [fabric selection機能] icons初期化
    elementSelectionControlRemove = document.createElement('img')
    elementSelectionControlRemove.src = IMG_REMOVE
    elementSelectionControlRotate = document.createElement('img')
    elementSelectionControlRotate.src = IMG_ROTATE

    // fabric初期化
    fb = new fabric.Canvas(cRef.value, {
      isDrawingMode: false,
      width: vState.css.clientWidthInitial,
      height: vState.css.clientHeightInitial,
      selection: false
    })

    refreshFabricZoom(fb)

    fb.freeDrawingBrush.color = vState.marker.color
    fb.freeDrawingBrush.width = vState.marker.lineWidth

    refreshFabric(fb)

    fb.on('mouse:down', (e: fabric.IEvent<MouseEvent>) => {
      console.log('mouse:down', e)
      if (vState.marker.tool !== MARKER_TOOLS.EDIT) {
        drawingObject = null
        console.log(e.pointer)
        console.log(vState.css.marker.zoom)
        mouseFrom.x = e.pointer!.x / vState.css.marker.zoom
        mouseFrom.y = e.pointer!.y / vState.css.marker.zoom
        isDrawing = true
        if (vState.marker.tool === MARKER_TOOLS.TEXT) {
          drawText(fb, mouseFrom, 150, 16, vState.marker.color)
        }
      }
    })
    fb.on('mouse:move', (e: fabric.IEvent<MouseEvent>) => {
      // console.log('mouse:move', e)
      if (vState.marker.tool === MARKER_TOOLS.EDIT || isDrawing === false) {
        return
      }
      mouseTo.x = e.pointer!.x / vState.css.marker.zoom
      mouseTo.y = e.pointer!.y / vState.css.marker.zoom
      const activeObject = fb.getActiveObject()
      if (activeObject === null || activeObject === undefined) {
        if (drawingObject) {
          fb.remove(drawingObject)
        }
        const fabricObject = drawing(
          fb,
          vState.marker.tool,
          mouseFrom,
          mouseTo,
          vState.marker.color,
          4,
          vState.css.marker.zoom
        )

        if (fabricObject) {
          setObjectEditable(fabricObject, false)
          fb.add(fabricObject)
          drawingObject = fabricObject
        }
      }
    })
    fb.on('mouse:up', (e: fabric.IEvent<MouseEvent>) => {
      console.log('mouse:up', e)
      if (vState.marker.tool !== MARKER_TOOLS.EDIT) {
        mouseTo.x = e.pointer!.x / vState.css.marker.zoom
        mouseTo.y = e.pointer!.y / vState.css.marker.zoom
        isDrawing = false
        const objAngleStep1 = getFabricItemByName(fb, 'AngleStep1')
        const objAngleStep2 = getFabricItemByName(fb, 'AngleStep2')
        if (objAngleStep1 !== null && objAngleStep2 !== null) {
          objAngleStep1.name = 'AngleStep1Finished'
          objAngleStep2.name = 'AngleStep1Finished'
          const fabricObject = new fabric.Group(
            [objAngleStep1, objAngleStep2],
            {
              originX: 'center',
              originY: 'center',
              name: MARKER_TOOLS.ANGLE
            }
          )
          setObjectEditable(fabricObject, false)
          fb.add(fabricObject)
          fb.remove(objAngleStep1)
          fb.remove(objAngleStep2)
          drawingObject = fabricObject
        }
        const targetObj = e.target || drawingObject
        if (targetObj) {
          // fb.discardActiveObject()
          // fb.setActiveObject(targetObj)
          fb.requestRenderAll()
        }
      }

      addFabricHistory(fb)
    })

    fb.on('selection:updated', (e: fabric.IEvent) => {
      console.log('selection:updated', e)
      changeFabricSelection(
        e,
        fb,
        elementSelectionControlRotate,
        elementSelectionControlRemove
      )
    })
    fb.on('selection:created', (e: fabric.IEvent) => {
      console.log('selection:created', e)
      changeFabricSelection(
        e,
        fb,
        elementSelectionControlRotate,
        elementSelectionControlRemove
      )
    })
    fb.on('object:modified', (e: fabric.IEvent) => {
      console.log('object:modified', e)
      addFabricHistory(fb)
    })
  }

  const resizeMarker = () => {
    console.log('resizeMarker')
    const rate = vRef.value.clientWidth / vState.css.marker.width
    console.log(rate)

    vState.css.marker.zoom = vState.css.marker.zoom * rate
    vState.css.marker.width = vState.css.marker.width * rate
    vState.css.marker.height = vState.css.marker.height * rate
  }

  const fbReversal = () => {
    fb.getObjects().forEach((v) => {
      if (v.type !== 'textbox') {
        v.toggle('flipX')
      } else {
        v.textAlign = v.textAlign === 'left' ? 'right' : 'left'
      }

      if (v.type !== 'group') {
        if (v.originX === 'left') {
          v.left = fb.width / fb.getZoom() - v.width - v.left
        } else {
          v.left = fb.width / fb.getZoom() - v.left + v.width
        }
      } else {
        v.left = fb.width / fb.getZoom() - v.left
      }

      v.setCoords()
    })
    fb.renderAll()
  }

  onMounted(() => {
    // video初期化
    console.log('useVideoPlayer: onMounted')

    const initState = () => {
      if (vState.currentTime === 0 && vState.timeDesignation.start !== 0) {
        vState.currentTime = vState.timeDesignation.start
      }
      if (vState.volume === -1) {
        vState.volume = isIOS() ? 0 : 1
      }
    }
    initState()
    if (url.endsWith('.m3u8')) {
      if (vRef.value.canPlayType('application/vnd.apple.mpegurl')) {
        console.log('-------------------------apple hls')
        vRef.value.src = url
      } else {
        console.log('-------------------------hls')
        let player = videojs(vRef.value)
        player.src({
          src: url,
          type: 'application/x-mpegURL'
        })
        player.on('seeking', () => {
          retryTimes = 0
          if (timeoutId) {
            clearTimeout(timeoutId)
          }
          timeoutId = setTimeout(() => {
            useShowGlobalLoading().value = false
          }, 30 * 1000)
          useVideoLoading().value = true
        })
        player.on('seeked', () => {
          retryTimes = 0
          if (timeoutId) {
            clearTimeout(timeoutId)
          }
          useVideoLoading().value = false
        })
        player.on('error', () => {
          console.log('hls play error--------------------------------------')
          console.log(player.error())
          normalErrorDialog()
        })
        player.tech().on('retryplaylist', () => {
          retryTimes++
          if (retryTimes >= 3) {
            if (retryTimes <= 3) {
              normalErrorDialog()
            }
            return
          }
          useVideoLoading().value = true
        })
      }
    } else {
      console.log('-------------------------normal video')
      vRef.value.src = url
    }
    vRef.value.addEventListener('canplay', handleCanplay)
    vRef.value.addEventListener('loadeddata', handleLoadeddata)
    vRef.value.addEventListener('loadedmetadata', handlelLoadedmetadata)
    vRef.value.addEventListener('timeupdate', handleTimeupdate)
    vRef.value.addEventListener('pause', handlePause)
    vRef.value.addEventListener('play', handlePlay)
    vRef.value.addEventListener('seeking', handleSeeking)
    vRef.value.addEventListener('seeked', handleSeeked)
    vRef.value.addEventListener('ratechange', handleRatechange)
    // vRef.value.addEventListener('volumechange', handleVolumechange)
    vRef.value.addEventListener('ended', handleEnded)
    vRef.value.addEventListener('error', handleError)

    videoResizeObserver.observe(vRef.value)

    vState.controller?.setVolume(vState.volume)

    if (!vState.playmodeConfig.pointIsPaused) {
      vState.playmodeConfig.pointIsPaused = true
      vState.controller?.setPlayModePointCurrentTime(
        vState.playmodeConfig.pointLogicCurrentTime
      )
      vState.controller?.togglePlayForPoint()
    } else if (!vState.isPaused) {
      vState.controller?.play().catch((e) => console.log(e))
    }
  })

  onBeforeUnmount(() => {
    console.log('useVideoPlayer: onBeforeUnmount')

    window.clearInterval(vState.playmodeConfig.pointIntervalId)

    vRef.value.removeEventListener('canplay', handleCanplay)
    vRef.value.removeEventListener('loadeddata', handleLoadeddata)
    vRef.value.removeEventListener('durationchange', handleDurationchange)
    vRef.value.removeEventListener('timeupdate', handleTimeupdate)
    vRef.value.removeEventListener('pause', handlePause)
    vRef.value.removeEventListener('play', handlePlay)
    vRef.value.removeEventListener('seeking', handleSeeking)
    vRef.value.removeEventListener('seeked', handleSeeked)
    vRef.value.removeEventListener('ratechange', handleRatechange)
    // vRef.value.removeEventListener('volumechange', handleVolumechange)
    vRef.value.removeEventListener('ended', handleEnded)
    vRef.value.removeEventListener('error', handleError)

    videoResizeObserver.disconnect()
  })

  onUnmounted(() => {
    console.log('useVideoPlayer: onUnmounted')

    const cleanState = () => {
      // if (!vState.isPaused) {
      //   vState.isPaused = true
      // }
      vState.isCanShow = false

      // vState.currentTime = 0
      // vState.playmodeConfig.pointIsPaused = true
      // vState.playmodeConfig.pointLogicCurrentTime = 0

      vState.css.clientWidthInitial = 0
      vState.css.clientHeightInitial = 0
    }

    cleanState()

    fb.clear()
  })

  const controller = {
    initData: () => {
      Object.assign(vState, JSON.parse(JSON.stringify(initState)))
    },
    setCurrentTime: (currentTime: number) => {
      vRef.value.currentTime = currentTime
    },
    setCurrentTimeForce: (currentTime: number) => {
      vRef.value.currentTime = currentTime
      vState.currentTime = currentTime
    },
    setPlayModePointCurrentTimeLogicOnly: (currentTime: number) => {
      console.log('setPlayModePointCurrentTimeLogicOnly')

      const pendingTimeBefore = vState.playmodeConfig.pointPendingTime.before
      if (currentTime < pendingTimeBefore) {
        vRef.value.currentTime = 0
      } else if (
        currentTime >= pendingTimeBefore &&
        currentTime < pendingTimeBefore + vState.duration
      ) {
        vRef.value.currentTime = currentTime - pendingTimeBefore
      } else if (currentTime >= pendingTimeBefore + vState.duration) {
        vRef.value.currentTime = vState.duration
      }
    },
    setPlayModePointCurrentTime: (currentTime: number) => {
      console.log('setPlayModePointCurrentTime')
      vState.playmodeConfig.pointLogicCurrentTime = currentTime

      const pendingTimeBefore = vState.playmodeConfig.pointPendingTime.before
      if (currentTime < pendingTimeBefore) {
        vRef.value.currentTime = 0
        vRef.value.pause()
      } else if (
        currentTime >= pendingTimeBefore &&
        currentTime < pendingTimeBefore + vState.duration
      ) {
        vRef.value.currentTime = currentTime - pendingTimeBefore
        vRef.value.pause()
      } else if (currentTime >= pendingTimeBefore + vState.duration) {
        vRef.value.currentTime = vState.duration
        vRef.value.pause()
      }
    },
    setPlayMode: (value: PLAY_MODE) => {
      vState.playmode = value
    },
    setPlayModeConfigLengthAnotherDuration: (value: number) => {
      vState.playmodeConfig.lengthAnotherDuration = value
    },
    setPlayModeConfigPointSetTime: (value: number) => {
      vState.playmodeConfig.pointSetTime = value
    },
    setPlayModeConfigPointLogicCurrentTime: (value: number) => {
      vState.playmodeConfig.pointLogicCurrentTime = value
    },
    setPlayModeConfigPointPendingTimeBefore: (value: number) => {
      vState.playmodeConfig.pointPendingTime.before = value
    },
    setPlayModeConfigPointPendingTimeAfter: (value: number) => {
      vState.playmodeConfig.pointPendingTime.after = value
    },
    setPlaybackRate: (playbackRate: number) => {
      vState.playbackRate = playbackRate
      setPlaybackRateFixed()
    },
    setVolume: (volume: number) => {
      console.log('setVolume', volume)
      vRef.value.volume = volume
      vState.volume = volume

      vRef.value.muted = false

      if (volume > 0) {
        vState.isMuted = false
      } else {
        vState.isMuted = true
        vRef.value.muted = true
      }
    },
    toggleMute: () => {
      console.log('toggleMute')
      if (vRef.value!.volume > 0) {
        vRef.value!.volume = 0
      } else {
        vRef.value!.volume = vState.volume
      }
    },
    setTimeDesignationStart: (start: number) =>
      (vState.timeDesignation.start = start),
    setTimeDesignationEnd: (end: number) => (vState.timeDesignation.end = end),
    getTimeDesignationEnd: () => {
      return vState.timeDesignation.end
    },
    toggleLoop: () => {
      vState.isLoop = !vState.isLoop
    },
    setTransparency: (transparency: number) =>
      (vState.transparency = transparency),
    toggleReversal: () => {
      vState.isReversal = !vState.isReversal
      fbReversal()
    },
    toggleGrid: () => (vState.isGrid = !vState.isGrid),
    play: () =>
      vRef.value.paused ? vRef.value.play().catch((e) => console.log(e)) : null,
    pause: () => {
      console.log('pause')
      if (!vRef.value.paused) {
        console.log('attemp to pause')
        vRef.value.pause()
      }
    },
    setPauseLogic: (value: boolean) => {
      console.log('setPauseLogic')
      vState.isPaused = value
    },
    togglePlay: () => {
      vRef.value.paused
        ? vRef.value.play().catch(() => {
            setTimeout(() => {
              vRef.value.play().catch((e) => console.log(e))
            }, 1000)
          })
        : vRef.value.pause()
    },
    togglePlayForPoint: () => {
      console.log('togglePlayForPoint')

      if (vState.playmodeConfig.pointIsPaused) {
        const intervalMs = 100
        if (
          vState.playmodeConfig.pointLogicCurrentTime >=
          vState.playmodeConfig.pointPendingTime.before +
            vState.playmodeConfig.pointPendingTime.after +
            vState.duration
        ) {
          vState.playmodeConfig.pointLogicCurrentTime = 0
          vRef.value.currentTime = 0
        }
        vState.playmodeConfig.pointIntervalId = window.setInterval(() => {
          const pendingTimeBefore =
            vState.playmodeConfig.pointPendingTime.before
          const pendingTimeAfter = vState.playmodeConfig.pointPendingTime.after
          const durationLogic =
            pendingTimeBefore + pendingTimeAfter + vState.duration

          const timeNext =
            vState.playmodeConfig.pointLogicCurrentTime +
            (intervalMs / 1000) * vState.playbackRate
          if (timeNext >= durationLogic) {
            if (vState.isLoop) {
              vState.playmodeConfig.pointLogicCurrentTime = 0
              vRef.value.currentTime = 0
              vRef.value.pause()
              return
            } else if (
              Math.abs(
                vState.playmodeConfig.pointLogicCurrentTime - durationLogic
              ) < 0.01
            ) {
              vState.playmodeConfig.pointLogicCurrentTime = durationLogic
              vRef.value.pause()
              if (vState.isLoop) {
                return
              } else {
                vState.playmodeConfig.pointIsPaused = true
                return window.clearInterval(
                  vState.playmodeConfig.pointIntervalId
                )
              }
            } else {
              vState.playmodeConfig.pointIsPaused = true
              vState.playmodeConfig.pointLogicCurrentTime = durationLogic
              return window.clearInterval(vState.playmodeConfig.pointIntervalId)
            }
          }

          vState.playmodeConfig.pointIsPaused = false

          if (
            vRef.value.paused &&
            timeNext >= pendingTimeBefore &&
            timeNext < pendingTimeBefore + vState.duration
          ) {
            vRef.value.play().catch(() => {
              setTimeout(() => {
                vRef.value?.play().catch((e) => console.log(e))
              }, 1000)
            })
          } else if (
            !vRef.value.paused &&
            timeNext >= pendingTimeBefore + vState.duration
          ) {
            vRef.value.currentTime = vState.duration
            vRef.value.pause()
          }
          vState.playmodeConfig.pointLogicCurrentTime = timeNext
        }, intervalMs)

        vState.playmodeConfig.pointIsPaused = false
      } else {
        window.clearInterval(vState.playmodeConfig.pointIntervalId)
        vState.playmodeConfig.pointIsPaused = true
        vRef.value.pause()
      }
    },
    setCssOverlayOpacity: (value: number) => {
      vState.css.overlay.opacity = value
    },
    setCssOverlayClipWidth: (value: number) => {
      vState.css.overlay.clipWidth = value
    },
    setCssOverlayClipHeight: (value: number) => {
      vState.css.overlay.clipHeight = value
    },
    setCssOverlayTranslateX: (value: number) => {
      vState.css.overlay.translateX = value
    },
    setCssOverlayTranslateY: (value: number) => {
      vState.css.overlay.translateY = value
    },
    setCssOverlayScaleX: (value: number) => {
      vState.css.overlay.scaleX = value
    },
    setCssOverlayScaleY: (value: number) => {
      vState.css.overlay.scaleY = value
    },
    setMarkerTool: (tool: MARKER_TOOLS) => {
      vState.marker.tool = tool
      if (fb) {
        fb.isDrawingMode = tool === MARKER_TOOLS.PEN
        if (tool === MARKER_TOOLS.EDIT) {
          fb.hoverCursor = 'move'
          setAllObjectsEditable(fb, true)
        } else if (tool === MARKER_TOOLS.EDIT_DISABLED) {
          fb.hoverCursor = ''
          setAllObjectsEditDisable(fb)
        } else {
          fb.hoverCursor = ''
        }
      }
    },
    setMarkerColor: (color: MARKER_COLORS) => {
      vState.marker.color = color
      fb.freeDrawingBrush.color = color
    },
    setMarkerHistoryBack: () => {
      setFabricHistoryBack(fb)
    },
    setMarkerHistoryForward: () => {
      setFabricHistoryForward(fb)
    },
    getMarkerHistoryRecentTs: () => {
      console.log('getMarkerHistoryRecentTs')
      let listNewTs = -1
      let listBackupNewTs = -1
      if (vState.marker.history.list.length > 0) {
        listNewTs =
          vState.marker.history.list[vState.marker.history.list.length - 1].ts
      }
      if (vState.marker.history.listBackup.length > 0) {
        listBackupNewTs =
          vState.marker.history.listBackup[
            vState.marker.history.listBackup.length - 1
          ].ts
      }
      return { listNewTs, listBackupNewTs }
    },
    cleanMarkerHistoryBackup: () => {
      vState.marker.history.listBackup.splice(0)
    },
    setMarkerClear: (date: number) => {
      fb.clear()
      addFabricHistory(fb, date)
    }
  }

  vState.controller = controller
  return controller
}
