import { UseFetchOptions } from '#app'
import { errInfo, openApiDialogWithDefault, pageRefresh } from '@/utils/common'

// 団体
export const useOrganizationApi = async (
  url: string,
  opts: UseFetchOptions<any>
) => {
  const defaultOptions: UseFetchOptions<any> = {
    onResponseError({ request, response, options }) {
      // 応答エラーを処理する
      const httpStatusCode = response.status
      const jsonCode = response._data?.code
      const jsonMessage = response._data?.message

      // サービス利用コード入力時 errors
      switch (httpStatusCode) {
        case 400: {
          // 400 ライセンス開始 無効な情報
          if (request === licenseStartUrl) {
            openApiDialogWithDefault(httpStatusCode)
            break
          }
          // 400 ライセンス終了 無効な情報
          if (request === licenseStopUrl) {
            openApiDialog('エラーが発生しました', jsonMessage, pageRefresh)
            break
          }
          break
        }
        case 429: {
          // 429 ライセンス開始 入力回数制限
          if (request === licenseStartUrl) {
            openApiDialogWithDefault(jsonCode)
            break
          } else {
            // 429 共通
            openApiDialogWithDefault(jsonCode)
            const { title, message } = errInfo(httpStatusCode)
            openApiDialog(title, message, pageRefresh)
          }
          break
        }
        case 403: {
          // 403はライセンス開始のみをブロックし、他は許可する
          if (request === licenseStartUrl) {
            // E403_01
            const { title, message } = errInfo(jsonCode)
            openApiDialog(title, message)
          }
          // 403 共通 共同でそれぞれ独自に判断する
          break
        }
        // other cases
        case 500:
        case 405:
        case 404:
          openApiDialog('エラーが発生しました', jsonMessage)
          break
        case 401:
          if (jsonCode === 'E401_02') {
            const { title, message } = errInfo(jsonCode)
            openApiDialog(title, message)
          } else {
            openApiDialog('エラーが発生しました', jsonMessage, reGetToken)
          }
          break
      }
    }
  }
  return await useFetch(url, { ...defaultOptions, ...opts } as any)
}
// 非団体
export const useApi = async (url: string, opts: UseFetchOptions<any>) => {
  // return await request({
  //   url,...opts
  // })
  const defaultOptions: UseFetchOptions<any> = {
    onResponseError({ response }) {
      const httpStatusCode = response.status
      const jsonMessage = response._data?.message
      switch (httpStatusCode) {
        case 400:
        case 500:
        case 405:
        case 404:
          openApiDialog('エラーが発生しました。', jsonMessage)
          break
        case 401:
          openApiDialog('エラーが発生しました。', jsonMessage, reGetToken)
          break
      }
    }
  }
  return await useFetch(url, { ...defaultOptions, ...opts } as any)
}
