interface HttpParams {
  baseUrl?: string
  url: string
  method?: any
  query?: any
  body?: any
}

export const request = (obj: HttpParams) => {
  const res = new Promise<void>(async (resolve, reject) => {
    const { data, pending, error, refresh } = await useFetch(obj.url, {
      method: obj.method ?? 'GET',
      query: obj.query ?? null,
      body: obj.body ?? null,
      onRequest({ request, options }) {
        // 设置请求报头
        options.headers = options.headers || {}
      },
      onRequestError({ request, options, error }) {
        // 处理请求错误
        reject(error)
      },
      onResponse({ request, response, options }) {
        // 处理响应数据
        const result = response._data
        const httpStatusCode = response.status

        const regex =
          /^\/organization\/api(?!.*?(\/license\/start|\/license\/stop|\/organizations)$)/
        if (
          !regex.test(request) ||
          (httpStatusCode !== 429 && httpStatusCode !== 403)
        ) {
          switch (result.code) {
            case 'E400_01':
              if (!regex.test(request)) {
                openApiDialog('エラーが発生しました。', '')
              }
              break
            case 'E500_01':
            case 'E405_01':
            case 'E404_01':
              openApiDialog('エラーが発生しました。', '')
              break
            case 'E401_01':
              openApiDialog('エラーが発生しました。', '', reGetToken)
            default:
              resolve(response._data)
          }
        }
      },
      onResponseError({ request, response, options }) {
        // 处理响应错误
        reject(response)
      }
    })
  })
  return res
}
