export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"hid":"description","name":"description","content":"「マネして学ぶ」新しいトレーニングサービス！筑波大学他監修の「お手本動画」「練習メニュー動画」、2つの動画を比較できる「比較機能」を特徴としています。自主的な学び合いや振り返りによる気づき、専門外競技の指導に対する不安の解消等が期待できます。"},{"hid":"keywords","name":"keywords","content":"練習,スポーツ,スポーツ指導,AI,ソフトバンク"},{"hid":"og:site_name","property":"og:site_name","content":"AIスマートコーチ"},{"hid":"og:url","property":"og:url","content":"https://dev.smartcoach.mb.softbank.jp/"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:image","property":"og:image","content":"https://dev.smartcoach.mb.softbank.jp/ogp.png"},{"hid":"og:title","property":"og:title","content":"AIスマートコーチ"},{"hid":"og:description","property":"og:description","content":"「マネして学ぶ」新しいトレーニングサービス！筑波大学他監修の「お手本動画」「練習メニュー動画」、2つの動画を比較できる「比較機能」を実装。学び合いや振り返りによる気づき、指導の不安の解消等が期待できます。"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.svg"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"},{"rel":"canonical","href":"https://smartcoach.mb.softbank.jp/lp/"}],"style":[],"script":[{"src":"https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=IntersectionObserver,ResizeObserver,WebAnimations,Object.fromEntries,Array.prototype.at"}],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1","title":"AIスマートコーチ"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"