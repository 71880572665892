import { default as _47var_47www_47web_47src_47pages_47compare_46vueMeta } from "/var/www/web/src/pages/compare.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47contents_46vueMeta } from "/var/www/web/src/pages/contents.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47copyright_46vueMeta } from "/var/www/web/src/pages/copyright.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47faq_46vueMeta } from "/var/www/web/src/pages/faq.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47history_46vueMeta } from "/var/www/web/src/pages/history.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47index_46vueMeta } from "/var/www/web/src/pages/index.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47info_46vueMeta } from "/var/www/web/src/pages/info.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47my_list_46vueMeta } from "/var/www/web/src/pages/my_list.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47organizations_46vueMeta } from "/var/www/web/src/pages/organizations.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47performers_46vueMeta } from "/var/www/web/src/pages/performers.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47play_video_46vueMeta } from "/var/www/web/src/pages/play_video.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47privacy_46vueMeta } from "/var/www/web/src/pages/privacy.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47sc_46vueMeta } from "/var/www/web/src/pages/sc.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47terms_46vueMeta } from "/var/www/web/src/pages/terms.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47use_46vueMeta } from "/var/www/web/src/pages/use.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47video_resolution_46vueMeta } from "/var/www/web/src/pages/video_resolution.vue?macro=true";
import { default as _47var_47www_47web_47src_47pages_47youtube_46vueMeta } from "/var/www/web/src/pages/youtube.vue?macro=true";
export default [
  {
    name: _47var_47www_47web_47src_47pages_47compare_46vueMeta?.name ?? "compare",
    path: _47var_47www_47web_47src_47pages_47compare_46vueMeta?.path ?? "/compare",
    file: "/var/www/web/src/pages/compare.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47compare_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47compare_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47compare_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/compare.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47contents_46vueMeta?.name ?? "contents",
    path: _47var_47www_47web_47src_47pages_47contents_46vueMeta?.path ?? "/contents",
    file: "/var/www/web/src/pages/contents.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47contents_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47contents_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47contents_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/contents.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47copyright_46vueMeta?.name ?? "copyright",
    path: _47var_47www_47web_47src_47pages_47copyright_46vueMeta?.path ?? "/copyright",
    file: "/var/www/web/src/pages/copyright.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47copyright_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47copyright_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47copyright_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/copyright.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47faq_46vueMeta?.name ?? "faq",
    path: _47var_47www_47web_47src_47pages_47faq_46vueMeta?.path ?? "/faq",
    file: "/var/www/web/src/pages/faq.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47faq_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47faq_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47faq_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47history_46vueMeta?.name ?? "history",
    path: _47var_47www_47web_47src_47pages_47history_46vueMeta?.path ?? "/history",
    file: "/var/www/web/src/pages/history.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47history_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47history_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47history_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/history.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47index_46vueMeta?.name ?? "index",
    path: _47var_47www_47web_47src_47pages_47index_46vueMeta?.path ?? "/",
    file: "/var/www/web/src/pages/index.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47index_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47index_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47info_46vueMeta?.name ?? "info",
    path: _47var_47www_47web_47src_47pages_47info_46vueMeta?.path ?? "/info",
    file: "/var/www/web/src/pages/info.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47info_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47info_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47info_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/info.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47my_list_46vueMeta?.name ?? "my_list",
    path: _47var_47www_47web_47src_47pages_47my_list_46vueMeta?.path ?? "/my_list",
    file: "/var/www/web/src/pages/my_list.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47my_list_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47my_list_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47my_list_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/my_list.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47organizations_46vueMeta?.name ?? "organizations",
    path: _47var_47www_47web_47src_47pages_47organizations_46vueMeta?.path ?? "/organizations",
    file: "/var/www/web/src/pages/organizations.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47organizations_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47organizations_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47organizations_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/organizations.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47performers_46vueMeta?.name ?? "performers",
    path: _47var_47www_47web_47src_47pages_47performers_46vueMeta?.path ?? "/performers",
    file: "/var/www/web/src/pages/performers.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47performers_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47performers_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47performers_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/performers.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47play_video_46vueMeta?.name ?? "play_video",
    path: _47var_47www_47web_47src_47pages_47play_video_46vueMeta?.path ?? "/play_video",
    file: "/var/www/web/src/pages/play_video.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47play_video_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47play_video_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47play_video_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/play_video.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47privacy_46vueMeta?.name ?? "privacy",
    path: _47var_47www_47web_47src_47pages_47privacy_46vueMeta?.path ?? "/privacy",
    file: "/var/www/web/src/pages/privacy.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47privacy_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47privacy_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47privacy_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47sc_46vueMeta?.name ?? "sc",
    path: _47var_47www_47web_47src_47pages_47sc_46vueMeta?.path ?? "/sc",
    file: "/var/www/web/src/pages/sc.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47sc_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47sc_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47sc_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/sc.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47terms_46vueMeta?.name ?? "terms",
    path: _47var_47www_47web_47src_47pages_47terms_46vueMeta?.path ?? "/terms",
    file: "/var/www/web/src/pages/terms.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47terms_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47terms_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47terms_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47use_46vueMeta?.name ?? "use",
    path: _47var_47www_47web_47src_47pages_47use_46vueMeta?.path ?? "/use",
    file: "/var/www/web/src/pages/use.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47use_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47use_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47use_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/use.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47video_resolution_46vueMeta?.name ?? "video_resolution",
    path: _47var_47www_47web_47src_47pages_47video_resolution_46vueMeta?.path ?? "/video_resolution",
    file: "/var/www/web/src/pages/video_resolution.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47video_resolution_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47video_resolution_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47video_resolution_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/video_resolution.vue").then(m => m.default || m)
  },
  {
    name: _47var_47www_47web_47src_47pages_47youtube_46vueMeta?.name ?? "youtube",
    path: _47var_47www_47web_47src_47pages_47youtube_46vueMeta?.path ?? "/youtube",
    file: "/var/www/web/src/pages/youtube.vue",
    children: [],
    meta: _47var_47www_47web_47src_47pages_47youtube_46vueMeta,
    alias: _47var_47www_47web_47src_47pages_47youtube_46vueMeta?.alias || [],
    redirect: _47var_47www_47web_47src_47pages_47youtube_46vueMeta?.redirect || undefined,
    component: () => import("/var/www/web/src/pages/youtube.vue").then(m => m.default || m)
  }
]