import { openApiDialog, useApiDialogCallback } from '~/composables/common'
import { errInfo } from '~/utils/common'

export const getToken = async () => {
  const isFirst = await getDB('common', 'isFirst')
  if (isFirst === undefined || isFirst) {
    await request({
      url: '/organization/api/1.1/users/register',
      method: 'POST'
    })
      .then((result) => {})
      .catch((error) => {
        console.log(error)
      })
    await putDB('common', 'isFirst', true)
    await deleteDB('common', 'isOrganization')
    await deleteDB('common', 'tabSelected')
    await deleteDB('common', 'addOnlineVideoIsOrganization')
    await deleteDB('common', 'addOnlineVideoTabSelected')
    const historyKeys = await getDBAllKeysFromIndex('history', 'organization')
    historyKeys.forEach(async (key) => {
      await deleteDB('history', key)
    })
    const downloadKeys = await getDBAllKeysFromIndex('download', 'organization')
    downloadKeys.forEach(async (key) => {
      await deleteDB('download', key)
    })
    await deleteDB('common', 'stopOrganizationHistory')
  }
}

export const getEvents = async () => {
  return await request({
    url: '/api/1.1/events'
  })
    .then((result) => {
      if (result.code == 'S200_01') {
        return result.data
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getHomeCategories = async (event_ids: string) => {
  return await request({
    url: '/api/1.1/home-categories',
    query: {
      event_ids: event_ids
    }
  })
    .then((result) => {
      if (result.code == 'S200_01') {
        return result.data
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getHomeOrganizations = async () => {
  return await request({
    url: '/organization/api/1.1/organizations'
  })
    .then((result) => {
      if (result.code == 'S200_01') {
        return result.data
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getTopData = async (event_ids: string) => {
  return await request({
    url: '/api/1.1/top',
    query: {
      event_ids: event_ids
    }
  })
    .then((result) => {
      if (result.code == 'S200_01') {
        return result.data
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getHomeVideoData = async (scene_id: number, page: number = 1) => {
  return await request({
    url: '/api/1.1/contents?scene_id=' + scene_id + '&page=' + page
  })
    .then((result) => {
      if (result.code == 'S200_01') {
        return {
          scene: result.scene,
          videos: result.data
        }
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getHomeOrganizationVideoData = async (
  organization_id: number,
  scene_id: number,
  page: number = 1
) => {
  return await request({
    url:
      '/organization/api/1.1/organizations/' +
      organization_id +
      '/scenes/' +
      scene_id +
      '/contents?page=' +
      page
  })
    .then((result) => {
      if (result.code == 'S200_01') {
        return {
          scene: result.scene,
          videos: result.data
        }
      }
    })
    .catch(async (error) => {
      await organizationError(
        error,
        organization_id,
        () => {
          location.reload()
        },
        false
      )
    })
}

export const getHomeCategoryData = async (
  event_ids: string,
  category_id: number
) => {
  return await request({
    url:
      '/api/1.1/scenes?event_ids=' +
      event_ids +
      '&home_category_id=' +
      category_id
  })
    .then((result) => {
      if (result.code == 'S200_01') {
        return result.data
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getHomeOrganizationData = async (organization_id: number) => {
  return await request({
    url: '/organization/api/1.1/organizations/' + organization_id + '/top'
  })
    .then((result) => {
      if (result.code == 'S200_01') {
        return result.data
      }
    })
    .catch(async (error) => {
      await organizationError(
        error,
        organization_id,
        () => {
          location.reload()
        },
        false
      )
    })
}

export const getTopNotices = async () => {
  return await request({
    url: '/api/1.1/notices/top'
  })
    .then((result) => {
      if (result.code == 'S200_01') {
        return result.data
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getOrganizationNotices = async (organization_id: number) => {
  return await request({
    url:
      '/organization/api/1.1/organizations/' + organization_id + '/notices/top'
  })
    .then((result) => {
      if (result.code == 'S200_01') {
        return result.data
      }
    })
    .catch(async (error) => {
      await organizationError(
        error,
        organization_id,
        () => {
          location.reload()
        },
        false
      )
    })
}
