import validate from "/var/www/web/src/node_modules/nuxt/dist/pages/runtime/validate.mjs";
import auth_45global from "/var/www/web/src/middleware/auth.global.ts";
import sample_45middleware_45global from "/var/www/web/src/middleware/sampleMiddleware.global.ts";
export const globalMiddleware = [
  validate,
  auth_45global,
  sample_45middleware_45global
]
export const namedMiddleware = {
  auth: () => import("/var/www/web/src/middleware/auth.ts"),
  "sample-middleware": () => import("/var/www/web/src/middleware/sampleMiddleware.ts")
}