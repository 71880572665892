// ID(複数可)指定コンテンツリスト取得API
export const getVideosByIDs = async (videoIDs: number[]) => {
  return await request({
    url: `/api/1.1/contents-by-ids?ids=${videoIDs}`
  })
    .then((result) => {
      if (result.code == 'S200_01') {
        return result.data
      }
    })
    .catch((error) => {})
}

// ID(複数可)指定コンテンツリスト取得API
export const getOrganizationVideosByIDs = async (
  organizationId: any,
  videoIDs: number[]
) => {
  return await request({
    url: `/organization/api/1.1/organizations/${organizationId}/contents-by-ids?ids=${videoIDs.toString()}`
  })
    .then((result) => {
      if (result.code == 'S200_01') {
        return [null, result.data]
      }
    })
    .catch((error) => {
      const httpStatusCode = error.status
      const jsonCode = error._data.code
      if (
        (httpStatusCode === 429 && jsonCode === 'E429_02') ||
        httpStatusCode === 403 ||
        httpStatusCode === 400
      ) {
        return [error, null]
      }
    })
}
