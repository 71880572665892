import { DBSchema, openDB } from 'idb'
import type {
  IDBPCursorWithValueIteratorValue,
  StoreNames,
  StoreValue
} from 'idb/with-async-ittr.js'

// indexedDB object
const dbPromise = ref()

interface MyDB extends DBSchema {
  common: {
    key: string
    value: any
  }
  myList: {
    value: object
    key: string
    indexes: { date: Date }
  }
  history: {
    key: string
    value: object
    indexes: {
      type: string
      organization: string
    }
  }
  download: {
    key: string
    value: object
  }
}

const connect = async () => {
  dbPromise.value = await openDB<MyDB>('ai', 3, {
    upgrade(db, oldVersion, newVersion, transaction) {
      if (oldVersion < 1) {
        db.createObjectStore('common')
        const myListStore = db.createObjectStore('myList', {
          keyPath: 'id',
          autoIncrement: true
        })
        myListStore.createIndex('date', 'date')
        db.createObjectStore('download')
      }

      if (oldVersion < 2) {
        const historyStore = db.createObjectStore('history', {
          keyPath: 'id',
          autoIncrement: true
        })
        historyStore.createIndex('type', 'type')
        historyStore.createIndex('organization', 'organization')
      }

      if (oldVersion < 3) {
        const downloadStore = transaction.objectStore('download')
        downloadStore.createIndex('type', 'type')
        downloadStore.createIndex('organization', 'organization')
      }
    }
  })
}

const connectDB = async () => {
  if (!(dbPromise.value instanceof IDBDatabase)) {
    await connect()
  }

  return dbPromise.value
}

export async function getDB(store: string, key: string | number) {
  return (await connectDB()).get(store, key)
}
export async function getDBAll(store: string) {
  return (await connectDB()).getAll(store)
}
export async function getDBAllKeys(store: string) {
  return (await connectDB()).getAllKeys(store)
}
export async function putDB(store: string, key: string | number, val: any) {
  return (await connectDB()).put(store, val, key)
}
export async function putDBWithoutKey(store: string, val: any) {
  return (await connectDB()).put(store, val)
}
export async function addDB(store: string, val: any) {
  return (await connectDB()).add(store, val)
}
export async function deleteDB(store: string, key: string | number) {
  return (await connectDB()).delete(store, key)
}
export async function clearDB(store: string) {
  return (await connectDB()).clear(store)
}
export async function getDBKeys(store: string) {
  return (await connectDB()).getAllKeys(store)
}
export async function getDBAllKeysFromIndex(store: string, key: string) {
  return (await connectDB()).getAllKeysFromIndex(store, key)
}
export async function getDBAllFromIndexWithValue(
  store: string,
  key: string,
  value: string
) {
  return (await connectDB()).getAllFromIndex(store, key, value)
}
export async function getDBAllFromIndex(store: string, key: string) {
  return (await connectDB()).getAllFromIndex(store, key)
}
export async function getPagedResult(storeName, pageSize, page, order) {
  const store = (await connectDB())
    .transaction(storeName)
    .store.iterate(undefined, order)
  return await getPageOfResults(store, pageSize, page)
}
async function getPageOfResults<
  DBTypes,
  StoreName extends StoreNames<DBTypes> = StoreNames<DBTypes>
>(
  cursorIterator: AsyncIterable<
    IDBPCursorWithValueIteratorValue<DBTypes, StoreNames<DBTypes>[], StoreName>
  >,
  pageSize: number,
  page: number
): Promise<StoreValue<DBTypes, StoreName>[]> {
  const items: StoreValue<DBTypes, StoreName>[] = []
  let i = 0
  const start = pageSize * (page - 1)
  const end = pageSize * page

  for await (const cursor of cursorIterator) {
    if (i >= start) {
      items.push(cursor.value)
    }
    i++
    if (i === end) break
  }

  return items
}
