export const useRegister = () =>
  useState('isFirst', () => {
    return (
      typeof localStorage !== 'undefined' &&
      localStorage.getItem('auth_web') !== 'true'
    )
  })

export const useRegistered = () => {
  useRegister().value =
    typeof localStorage !== 'undefined' &&
    localStorage.getItem('auth_web') !== 'true'
}

export const useUnregistered = () => {
  useRegister().value = true
}
