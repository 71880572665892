import { MARKER_TOOLS, MARKER_COLORS } from './marker'

export enum VIDEO_PLAYER_MODE {
  NORMAL = 'normal',
  HLS = 'hls',
  CACHE = 'cache'
}

export enum PLAY_MODE {
  // 通常再生
  NORMAL = 'NORMAL',
  // 長さ合わせ再生
  LENGTH = 'LENGTH',
  // ポイント合わせ再生: 設定済み
  POINT = 'POINT'
}

export type VideoPlayerUIState = {
  loaded: boolean
  progress: boolean
  grid: boolean
  hideBorder: boolean
  marker: boolean
  mouseEnterController: boolean
  borderAnimation: boolean
  playbackRateTips: boolean
  reversal: boolean
  pointBar: boolean
}

type MarkerHistoryItemInfo = {
  ts: number
  isReversal: boolean
  item: string
}

type MarkerState = {
  tool: MARKER_TOOLS
  color: MARKER_COLORS
  lineWidth: number
  history: {
    list: MarkerHistoryItemInfo[]
    listBackup: MarkerHistoryItemInfo[]
    cursor: number
    cursorMin: number
  }
}

export type VideoPlayerState = {
  // 動画URL
  url: string
  // スクショ
  screenshot: string
  // 動画の長さ(秒)
  duration: number
  // 再生位置(秒)
  currentTime: number
  // 速さ
  playbackRate: number
  // 導入モード: 通常導入(mp4など)、ストリーミング導入(HLS)、ダウンロード導入(キャッシュした動画)
  mode:
    | VIDEO_PLAYER_MODE.NORMAL
    | VIDEO_PLAYER_MODE.HLS
    | VIDEO_PLAYER_MODE.CACHE
  playmode: PLAY_MODE
  playmodeConfig: {
    // pointSetTimeのベースは動画自体のduration(PendingTime含めない)
    pointSetTime: number
    // pointLogicCurrentTimeはPendingTimeも含む。
    pointLogicCurrentTime: number
    // 動画自体のduration以外の時間
    pointPendingTime: {
      before: number
      after: number
    }
    pointIntervalId: number
    pointIsPaused: boolean
    lengthAnotherDuration: number
  }
  // 設定した音量値(mute禁用の時リカバリ用)
  volume: number
  // 透過度(0～1)
  transparency: number
  // 時間指定
  // timeDesignation: {
  //   start: number
  //   end: number
  // }
  // 動画のCSS属性
  css: {
    // 重ねるCSS属性
    overlay: {
      opacity: number
      clipWidth: number
      clipHeight: number
      translateX: number
      translateY: number
      scaleX: number
      scaleY: number
    }
    marker: {
      zoom: number
      width: number
      height: number
    }
    // 現在のclientWidth
    clientWidth: number
    // 現在のclientHeight
    clientHeight: number
    // 初期のclientWidth(マーカー用)
    clientWidthInitial: number
    // 初期のclientHeight(マーカー用)
    clientHeightInitial: number
    // transform: translateX(px)
    translateX: number
    // transform: translateY(px)
    translateY: number

    // transform: scaleX(px)
    scaleX: number
    // transform: scaleY(px)
    scaleY: number
  }
  // 再生できる状態の判定
  isCanplay: boolean
  // プレイヤー表示できる状態の判定
  isCanShow: boolean
  // 反転
  isReversal: boolean
  // ループ
  isLoop: boolean
  // グリッド
  isGrid: boolean
  // 再生状態判定
  isPaused: boolean
  // mute状態判定
  isMuted: boolean

  marker: MarkerState
  controller: ReturnType<typeof useVideoPlayer> | null
}
