// 非団体API

import { useOrganizationApi } from '@/composables/useApi'
import { errInfo, filterObjNilValue } from '@/utils/common'
import { returnStatement } from '@babel/types'

// ライセンス開始APIのURL
export const licenseStartUrl = '/organization/api/1.1/license/start'

// ライセンス終了APIのURL
export const licenseStopUrl = '/organization/api/1.1/license/stop'

// お知らせリスト取得API
export const getNormalInfo = async (page: number, eventIDs: string) => {
  return await useApi('/api/1.1/notices', {
    query: filterObjNilValue({ page: page, event_ids: eventIDs })
  })
}

// お知らせ取得API
export const getNormalInfoDetail = async (infoID: number, eventIDs: string) => {
  return await useApi(`/api/1.1/notices/${infoID}`, {
    query: filterObjNilValue({ event_ids: eventIDs })
  })
}

// 出演者リスト取得API
export const getNormalPerformers = async (page: number, eventIDs: string) => {
  return await useApi('/api/1.1/performers', {
    query: filterObjNilValue({ page: page, event_ids: eventIDs })
  })
}

// コピーライトリスト取得API
export const getNormalCopywriters = async (page: number) => {
  return await useApi('/api/1.1/copyrights', {
    query: { page: page }
  })
}

// 種目リスト取得API
export const getNormalEvents = async (page: number) => {
  return await useApi('/api/1.1/events', {
    query: { page: page }
  })
}

// 団体API

// ユーザー登録API
export const organizationLogin = async () => {
  await useOrganizationApi('/organization/api/1.1/users/register', {
    method: 'POST',
    credentials: 'include'
  })
}
// お知らせリスト取得API
export const getOrganizationInfo = async (
  organizationID: number,
  page: number
) => {
  return await useOrganizationApi(
    `/organization/api/1.1/organizations/${organizationID}/notices`,
    {
      query: { page: page },
      credentials: 'include'
    }
  )
}

// お知らせ取得API
export const getOrganizationInfoDetail = async (
  organizationID: number,
  infoID: number
) => {
  return await useOrganizationApi(
    `/organization/api/1.1/organizations/${organizationID}/notices/${infoID}`,
    {
      credentials: 'include'
    }
  )
}

// 出演者リスト取得API
export const getOrganizationPerformers = async (
  organizationID: number,
  page: number
) => {
  return await useOrganizationApi(
    `/organization/api/1.1/organizations/${organizationID}/performers`,
    {
      query: { page: page }
    }
  )
}

// 団体情報取得API
export const getOrganizations = async () => {
  return await useOrganizationApi('/organization/api/1.1/organizations', {
    credentials: 'include'
  })
}

// ライセンス開始API
export const organizationLicenseStart = async (
  serviceCode: string,
  passCode: string
) => {
  return await useOrganizationApi(licenseStartUrl, {
    method: 'POST',
    credentials: 'include',
    body: {
      service_code: serviceCode,
      pass_code: passCode
    }
  })
}

// ライセンス終了API
export const organizationLicenseStop = async (serviceCode: string) => {
  return await useOrganizationApi(licenseStopUrl, {
    method: 'POST',
    credentials: 'include',
    body: {
      service_code: serviceCode
    }
  })
}
