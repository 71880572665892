// menu select
import { deleteDB, getDBAllKeys } from '~/composables/idb'

export const useMenuSelected = () => useState('menuSelected', () => ref(''))
// menu width
export const useMenuWidth = () => useState('menuWidth', () => ref('320px'))

// menu folded
export const useMenuFolded = () => useState('menuFolded', () => ref(false))

// menu folded
export const useMenuScrollTop = () => useState('menuScrollTop', () => ref(0))

// downlaod progress
export const useDownloadProgress = () => useState('downloadProgress', () => 0)

// loading
export const useShowGlobalLoading = () => useState('globalLoading', () => false)

// Does Add Online Video Dialog Need Refresh
export const useAddOnlineVideoDialogRefresh = () =>
  useState('addOnlineVideoDialogRefresh', () => ref<boolean>(false))

// Analyse Unfold
export const useAnalyseUnfold = () =>
  useState('analyseUnfold', () => ref<boolean>(false))

// Analyse Unfold
export const useComparisonAspectRatio = () =>
  useState('comparisonAspectRatio', () => ref<boolean>(false))

// Video Loading
export const useVideoLoading = () =>
  useState('videoLoading', () => ref<boolean>(false))

// Video Analyse End
export const useVideoAnalyseEnd = () =>
  useState('videoAnalyseEnd', () => ref<boolean>(false))

// 401
export const reGetToken = async () => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('auth_web', 'false')
  }
  await putDB('common', 'isFirst', true)
  await getToken()
  window.location.reload()
}

// 429 403 400
// hasDialog只限制403配信团体的弹窗
export const organizationError = async (
  response,
  organization_id: number,
  callback: Function = () => {},
  hasDialog: Boolean = true
) => {
  const httpStatusCode = response.status
  const jsonCode = response._data.code
  switch (httpStatusCode) {
    case 429: {
      const { title, message } = errInfo(httpStatusCode)
      if (jsonCode === 'E429_02') {
        openApiDialog(title, message, () => {
          terminationOrganization(organization_id)
          callback()
        })
      } else {
        openApiDialog(title, message, callback)
      }
      break
    }
    case 403: {
      const organizations = await getHomeOrganizations()
      const organization = organizations.find(
        (organization) => organization.id == organization_id
      )
      if (organization.is_distributor) {
        if (hasDialog) {
          openApiDialog(
            'エラーが発生しました。',
            '動画が非公開になったため、アクセスできません。',
            callback
          )
        } else {
          callback()
        }
      } else {
        const { title, message } = errInfo(jsonCode)
        openApiDialog(title, message, callback)
      }
      break
    }
    case 400: {
      openApiDialog(
        'サービス利用コード無効',
        'サービス利用コードが無効になりました。詳細は所属団体の管理者にお問い合わせください。',
        async () => {
          await terminationOrganization(organization_id)
          callback()
        }
      )
    }
  }
}

export const terminationOrganization = async (organization_id: number) => {
  await deleteDeprecateOrganizationData(organization_id)
  const organizations = await getHomeOrganizations()
  const organization = organizations.find(
    (organization) => organization.id == organization_id
  )
  await organizationLicenseStop(organization?.service_code)
}

// API Dialog
export const useApiDialogShow = () => useState('apiDialogShow', () => false)
export const useApiDialogTitle = () => useState('apiDialogTitle', () => '')
export const useApiDialogMessage = () => useState('apiDialogMessage', () => '')
export const useApiDialogCallback = () =>
  useState('apiDialogCallback', () => new Function())

export const openApiDialog = (
  title: string,
  message: string,
  callback: Function = () => {}
) => {
  useShowGlobalLoading().value = false
  useApiDialogShow().value = true
  useApiDialogTitle().value = title
  useApiDialogMessage().value = message
  useApiDialogCallback().value = callback
}

// format duration
export const formatDuration = (milliseconds: number) => {
  const seconds = Math.floor((milliseconds / 1000000) % 60)
  const minutes = Math.floor((milliseconds / (1000000 * 60)) % 60)
  const hours = Math.floor(milliseconds / (1000000 * 60 * 60))

  const hoursStr = hours < 10 ? '0' + hours : hours
  const minutesStr = minutes < 10 ? '0' + minutes : minutes
  const secondsStr = seconds < 10 ? '0' + seconds : seconds

  return hoursStr + ':' + minutesStr + ':' + secondsStr
}

export const downloadContent = async (signal, video, type, organization_id) => {
  try {
    console.log('download begin')
    const response = await fetch(video.url, {
      method: 'GET',
      responseType: 'blob',
      signal: signal
    })

    if (!response.ok) {
      throw new Error('response is not ok')
    }

    const total = response.headers.get('Content-Length')
    let loaded = 0
    const reader = response.body.getReader()
    let blob = new Blob([])

    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        break
      }

      loaded += value.length
      let progress = Math.round((loaded * 100) / total) - 1
      useDownloadProgress().value = progress < 0 ? 0 : progress
      blob = new Blob([blob, value], { type: 'video/mp4' })
    }

    if (type == 'organizations') {
      await putDB('download', `organization_${organization_id}_${video.id}`, {
        id: video.id,
        type: 1,
        organization: organization_id,
        hash: video.hash,
        videoData: blob
      })
    } else {
      await putDB('download', `normal_${video.id}`, {
        id: video.id,
        type: 0,
        hash: video.hash,
        videoData: blob
      })
    }
    // todo save indexedDB
    useDownloadProgress().value = 100
    return blob
  } catch (error) {
    if (error.name === 'AbortError') {
      class AbortError extends Error {
        constructor(message: string) {
          super(message)
          this.name = 'fetchCancel'
          Object.setPrototypeOf(this, AbortError.prototype)
          this.stack = new Error(message).stack
        }
      }

      throw new AbortError('fetch cancel')
    } else {
      throw new Error(error)
    }
  }
}

export const isContentDownloaded = async (
  video: any,
  type: any,
  organization_id: any
) => {
  let downloadedVideo = {},
    key =
      type == 'organizations'
        ? `organization_${organization_id}_${video?.id}`
        : `normal_${video?.id}`
  downloadedVideo = await getDB('download', key)
  if (
    downloadedVideo === null ||
    downloadedVideo === undefined ||
    Object.keys(downloadedVideo).length === 0
  ) {
    return false
  }
  if (video?.hash !== downloadedVideo?.hash) {
    await deleteDB('download', key)
    return false
  }
  return true
}

export const getDownloadedContent = async (
  video: any,
  type: any,
  organization_id: any
) => {
  let key =
    type == 'organizations'
      ? `organization_${organization_id}_${video?.id}`
      : `normal_${video?.id}`
  const isDownloaded = await isContentDownloaded(video, type, organization_id)
  if (isDownloaded) {
    return await getDB('download', key)
  }
  return null
}

// 清理过期organization的download表数据
export const deleteDeprecateOrganizationData = async (organization_id: any) => {
  const keys = await getDBAllKeys('download')
  for (const key of keys) {
    if (key.includes(`organization_${organization_id}_`)) {
      await deleteDB('download', key)
    }
  }
}

export const normalErrorDialog = () => {
  useShowGlobalLoading().value = false
  openApiDialog('エラーが発生しました', '', () => {
    navigateTo('/')
  })
}

export const localVideoErrorDialog = () => {
  useShowGlobalLoading().value = false
  openApiDialog(
    'エラーが発生しました',
    '動画ファイルが壊れているか削除されたため見つかりません。選択し直してください。',
    () => {
      navigateTo('/my_list')
    }
  )
}

export const videoGoneDialog = () => {
  useShowGlobalLoading().value = false
  openApiDialog(
    'エラーが発生しました',
    '動画が非公開になったため、アクセスできません。',
    () => {
      navigateTo('/')
    }
  )
}
