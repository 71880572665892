interface ErrorJsonCode {
  [code: number | string]: {
    title: string
    message: string
  }
}
export const getFixedValue = (value: number, accuracy: number) => {
  return Math.floor(value * Math.pow(10, accuracy)) / Math.pow(10, accuracy)
}

export const throttle = (callback: Function, limit: number) => {
  let waiting = false
  return (...args: any[]) => {
    if (!waiting) {
      callback.apply(this, args)
      waiting = true
      setTimeout(() => {
        waiting = false
      }, limit)
    }
  }
}

export const getColorFromTheme = (theme: 'green' | 'blue') =>
  theme === 'green' ? '#1FFFF2' : '#3BD2FF'

export const getTimeStrBySeconds = (seconds: number) =>
  new Date(seconds * 1000).toISOString().slice(11, 19)

export const isIOS = () => {
  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const formatDate = (date: Date): string => {
  const year = date.getFullYear().toString()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return `${year}.${month}.${day}`
}

export const formatTime = (seconds: number): string => {
  seconds = Math.floor(seconds)
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - hours * 3600) / 60)
  const remainingSeconds = seconds - hours * 3600 - minutes * 60

  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0')

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
  } else {
    return `${formattedMinutes}:${formattedSeconds}`
  }
}

export const pageRefresh = () => {
  location.reload()
}

export const errInfo = (code: number | string) => {
  const jsonCodeErrs: ErrorJsonCode = {
    // 400
    E400_01: {
      title: '無効な情報',
      message:
        '入力情報が正しくありません。入力内容が正しいかご確認ください。または所属団体の管理者にお問い合わせください。'
    },
    // 403
    E403_01: {
      title: '利用権限停止中',
      message:
        'このサービス利用コードは停止中です。詳細は所属団体の管理者にお問い合わせください。'
    },
    // 403
    E403_02: {
      title: '同一団体での利用',
      message:
        'すでにご利用中の団体です。サービス利用コードを変更する場合は一度解除してから再度を登録してください。'
    },
    // 429
    E429_03: {
      title: '連続エラー',
      message:
        '連続して入力に失敗しました。しばらく経ってから再度お試しください。'
    },
    // 401
    E401_02: {
      title: '配信団体アカウントエラー',
      message: '配信団体のアカウントではログインできません。'
    }
    // more...
  }

  const httpStatusCodeErrs: ErrorJsonCode = {
    // 400
    400: {
      title: '無効な情報',
      message:
        '入力情報が正しくありません。入力内容が正しいかご確認ください。または所属団体の管理者にお問い合わせください。'
    },
    // 403
    403: {
      title: '利用権限停止中',
      message:
        'このサービス利用コードは停止中です。詳細は所属団体の管理者にお問い合わせください。'
    },
    // 404
    404: {
      title: 'エラーが発生しました',
      message: '無効な情報。'
    },
    // 429
    429: {
      title: '利用台数の制限',
      message:
        'このサービス利用コードでご利用できる端末台数を超えました。再度、団体して利用する場合は、メニュー内の団体利用からサービス利用コード情報を入力ください。'
    }
  }

  if (typeof code === 'string') {
    return checkMessageTitle(jsonCodeErrs[code])
  }
  return checkMessageTitle(httpStatusCodeErrs[code])
}

export const filterObjNilValue = (obj: object) => {
  const filteredObj: { [key: string]: any } = {}

  Object.entries(obj).forEach(([key, value]) => {
    if (value !== '' && value !== null && value !== undefined) {
      filteredObj[key] = value
    }
  })
  return filteredObj
}
export const checkMessageTitle = (titleMes: any) => {
  if (!titleMes) {
    return { title: 'エラーが発生しました', message: 'エラーが発生しました。' }
  }
  return titleMes
}

export const openApiDialogWithDefault = (
  code: string | number,
  callback: Function = () => {}
) => {
  const { title, message } = errInfo(code)
  openApiDialog(title, message, callback)
}

// もし a タグの URL が存在すれば、新しいタブで開く
export const handleBlank = (url: string | null) => {
  if (url === null) {
    return
  }
  window.open(url, '_blank')
}
