export enum MARKER_COLORS {
  BLUE = '#1FFFF2',
  BLUE2 = '#53C3FF',
  GREEN = '#21F292',
  PURPLE = '#8F43FF',
  PINK = '#FF43F1',
  RED = '#FF3434',
  YELLOW = '#F82',
  YELLOW2 = '#FFC400',
  BLACK = '#000000',
  GRAY = '#AAAAAA',
  WHITE = '#F5F5F5'
}

export enum MARKER_TOOLS {
  // 編集ツール
  EDIT = 'EDIT',
  EDIT_DISABLED = 'EDIT_DISABLED',
  // 通常ツール
  PEN = 'PEN',
  LINE = 'LINE',
  ARROW = 'ARROW',
  RULER = 'RULER',
  ANGLE = 'ANGLE',
  RECTANGLE = 'RECTANGLE',
  TRIANGLE = 'TRIANGLE',
  CIRCLE = 'CIRCLE',
  TEXT = 'TEXT'
}

export type Point = {
  x: number
  y: number
}
