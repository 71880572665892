import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    display: {
      thresholds: {
        xs: 320,
        sm: 768,
        md: 1024,
        lg: 1340,
        xl: 1920
      }
    },
    components,
    directives
  })
  nuxtApp.vueApp.use(vuetify)
})
