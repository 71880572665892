import {
  addDB,
  deleteDB,
  getDBAllFromIndex,
  getPagedResult
} from '~/composables/idb'
import {
  getOrganizationVideosByIDs,
  getVideosByIDs
} from '~/composables/historyRequest'

const enum VideoType {
  Normal,
  Organization
}

export const saveNormalHistoricalRecord = async (data: any) => {
  if (data !== null) {
    const timestamp = Date.parse(new Date().toString())
    await addDB(TABLE_NAME, { type: VideoType.Normal, timestamp, data })
  }
}

export const saveOrganizationHistoricalRecord = async (
  data: any,
  organization: any
) => {
  if (data !== null) {
    const timestamp = Date.parse(new Date().toString())
    await addDB(TABLE_NAME, {
      type: VideoType.Organization,
      timestamp,
      organization,
      data
    })
  }
}

export const getHistory = async (page) => {
  return await getHistoryFromCache(page, PAGE_SIZE)
}

export const refreshHistoryPage = async () => {
  await clearExpiredData()
  pageOfCache = 1
  remoteData.length = 0
  historyData.length = 0
  historyDataMap.clear()
  historyListCache.length = 0
  expiredData.length = 0
  expiredOrganizationData.length = 0
}

export const deleteDeprecateHistoryData = async (organizationId) => {
  const data = await getDBAllFromIndex(
    'history',
    'organization',
    organizationId
  )
  for (const item of data) {
    await deleteDB('history', item.id)
  }
}

// ----------------------------------------------------------------

const TABLE_NAME = 'history'
const PAGE_SIZE = 10
const PAGE_SIZE_OF_CACHE = 20
let pageOfCache = 1

// 远端数据
let remoteData = []
// 历史记录
let historyData = []
// map分流后的历史记录
let historyDataMap = new Map()
// 历史数据cache
const historyListCache = []
// 过期数据列表
const expiredData = []
// 过期数据列表(download表)
const expiredOrganizationData = []

function filterData(localData, remoteData, cache) {
  const data = filterDeprecate(localData, remoteData)

  return filterDuplicate(cache, data)
}

function setExpiredData(id: number) {
  if (id) {
    expiredData.push(id)
  }
}

function setExpiredOrganizationData(id: number) {
  if (id) {
    expiredOrganizationData.push(id)
  }
}

function filterDeprecate(localData, remoteData) {
  return localData.filter((historyItem) => {
    let flag = false
    if (historyItem.organization) {
      const organizationVideoList = remoteData.get(
        `${historyItem.organization}`
      )
      if (organizationVideoList) {
        for (const remoteItem of organizationVideoList) {
          if (checkDeprecate(historyItem, remoteItem)) {
            flag = true
            break
          }
        }
      }
    } else {
      const videoList = remoteData.get('normal')
      if (videoList) {
        for (const remoteItem of videoList) {
          if (checkDeprecate(historyItem, remoteItem)) {
            flag = true
            break
          }
        }
      }
    }
    if (!flag) {
      setExpiredData(historyItem.id)
    }

    return flag
  })
}

function filterDuplicate(cache, data) {
  const newData = unique(data)

  return cache.length === 0
    ? newData
    : newData.filter((item) => {
        let flag = true
        for (const cacheItem of cache) {
          if (checkDuplicate(item, cacheItem)) {
            setExpiredData(item.id)
            flag = false
            break
          }
        }
        return flag
      })
}

function unique(data) {
  let len = data.length
  for (let i = 0; i < len; i++) {
    for (let j = i + 1; j < len; j++) {
      if (checkDuplicate(data[i], data[j])) {
        setExpiredData(data[j].id)
        data.splice(j, 1)
        len--
        j--
      }
    }
  }
  return data
}

function checkDeprecate(historyItem, remoteItem) {
  return (
    historyItem.data.id === remoteItem.id &&
    historyItem.data.hash === remoteItem.hash
  )
}

function checkDuplicate(item, item2) {
  return (
    item.organization === item2.organization && item.data.id === item2.data.id
  )
}

function getVideoIds(data) {
  const result = new Set()
  data.forEach((item) => {
    result.add(item.data.id)
  })
  return Array.from(result)
}

function diffluenceHistory(data) {
  const result: Map<any, any[]> = new Map()
  const normal = []
  data.forEach((item) => {
    if (item.type === VideoType.Normal) {
      normal.push(item)
      result.set('normal', normal)
    } else {
      const organization = result.get(item.organization)
      if (organization) {
        organization.push(item)
        result.set(item.organization, organization)
      } else {
        result.set(item.organization, [item])
      }
    }
  })
  return result
}

async function getRemoteVideosByIds(origin) {
  if (!origin) return

  const result = new Map()
  for (const [key, value] of origin) {
    const data = value
    const organization = key
    if (organization === 'normal') {
      const videos = await getVideosByIDs(Array.from(getVideoIds(data)))
      if (videos) {
        result.set('normal', videos)
      }
    } else {
      const [err, videos] = await getOrganizationVideosByIDs(
        organization,
        getVideoIds(data)
      )
      if (videos) {
        result.set(organization, videos)
      }
      if (err) {
        setExpiredOrganizationData(organization)
        for (const item of data) {
          setExpiredData(item.id)
        }
      }
    }
  }
  return result
}

async function getHistoryFromCache(page, pageSize) {
  const start = pageSize * (page - 1)
  const end = pageSize * page

  let result = getData(start, end)

  while (result.length !== pageSize) {
    if (await extendedCache()) {
      result = getData(start, end)
    } else {
      break
    }
  }

  result = wrapperData(result)

  return result
}

function getData(start, end) {
  return historyListCache.slice(start, end)
}

async function wrapperData(data) {
  if (!data) return

  return await Promise.all(
    data.map(async (item) => {
      const result = item
      result.isDownload = await isContentDownloaded(
        item.data,
        item.organization ? 'organizations' : 'normal',
        item.organization
      )
      return result
    })
  )
}

async function extendedCache() {
  historyData = await getPagedResult(
    TABLE_NAME,
    PAGE_SIZE_OF_CACHE,
    pageOfCache,
    'prev'
  )
  if (historyData && historyData.length !== 0) {
    pageOfCache++
  } else {
    return false
  }

  historyDataMap = await diffluenceHistory(historyData)

  remoteData = await getRemoteVideosByIds(historyDataMap)

  const newData = filterData(historyData, remoteData, historyListCache)

  if (newData && newData.length > 0) {
    historyListCache.push(...newData)
  }

  return true
}

const clearExpiredData = () => {
  expiredData.forEach(async (id) => {
    await deleteDB(TABLE_NAME, id)
  })
  expiredData.length = 0
  expiredOrganizationData.forEach(async (id) => {
    await deleteDeprecateHistoryData(id)
  })
  expiredOrganizationData.length = 0
}
