import { useMenuSelected } from '~/composables/common'
import { useUnregistered } from '~/composables/store/useRegister'
import { refreshHistoryPage } from '~/composables/history'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const isFirst = computed(() => {
    return (
      typeof localStorage !== 'undefined' &&
      localStorage.getItem('auth_web') !== 'true'
    )
  })

  if (isFirst.value) {
    useUnregistered()
    if (to.path !== '/') {
      useRouter().push('/')
    }
  }

  const menuSelected = useMenuSelected()
  if (to.path === '/compare') {
    resetVideo1()
    resetVideo2()
  } else if (to.path === '/history') {
    await refreshHistoryPage()
  }
  menuSelected.value = to.path
})
