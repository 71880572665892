import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/var/www/web/src/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/var/www/web/src/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/var/www/web/src/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules_nuxt_bugsnag_dist_runtime_plugin_mjs_OcT8LIvbNC from "/var/www/web/src/node_modules/nuxt-bugsnag/dist/runtime/plugin.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "/var/www/web/src/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import plugins_Vue3Lottie_client_ts_bMZiOS6AB0 from "/var/www/web/src/plugins/Vue3Lottie.client.ts";
import plugins_bugSnag_ts_4r5a9r7GaF from "/var/www/web/src/plugins/bugSnag.ts";
import plugins_text_ts_sc37v2klBL from "/var/www/web/src/plugins/text.ts";
import plugins_vue_gtm_client_ts_stBjLl0OeM from "/var/www/web/src/plugins/vue-gtm.client.ts";
import plugins_vuetify_ts_7h9QAQEssH from "/var/www/web/src/plugins/vuetify.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules_nuxt_bugsnag_dist_runtime_plugin_mjs_OcT8LIvbNC,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc,
  plugins_Vue3Lottie_client_ts_bMZiOS6AB0,
  plugins_bugSnag_ts_4r5a9r7GaF,
  plugins_text_ts_sc37v2klBL,
  plugins_vue_gtm_client_ts_stBjLl0OeM,
  plugins_vuetify_ts_7h9QAQEssH
]