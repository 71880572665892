import { fabric } from 'fabric'
import { MARKER_TOOLS, MARKER_COLORS, Point } from '@/types/marker'

const LineLength = 700

export const setObjectEditable = (
  fabricObject: fabric.Object,
  editable: boolean
) => {
  fabricObject.hasBorders = editable
  fabricObject.hasControls = editable
  fabricObject.selectable = editable
  fabricObject.lockMovementX = !editable
  fabricObject.lockMovementY = !editable
  fabricObject.lockScalingX = !editable
  fabricObject.lockScalingY = !editable
}

export const setObjectEditDisable = (fabricObject: fabric.Object) => {
  fabricObject.selectable = false
}

export const setControlRender = (
  size: number,
  imageElement: HTMLImageElement
) => {
  return (
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    _styleOverride: any,
    fabricObject: fabric.Object
  ) => {
    ctx.save()
    ctx.translate(left, top)
    if (fabricObject.angle !== undefined) {
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
    }
    ctx.drawImage(imageElement, -size / 2, -size / 2, size, size)
    ctx.restore()
  }
}

export const changeControlsForObject = (
  fb: fabric.Canvas,
  fabricObj: fabric.Object,
  elementRotate: HTMLImageElement,
  elementRemove: HTMLImageElement
) => {
  const size = 24
  fabricObj.controls.mtr.x = -0.5
  fabricObj.controls.mtr.y = -0.5
  fabricObj.controls.mtr.offsetX = 0
  fabricObj.controls.mtr.offsetY = 0
  fabricObj.controls.mtr.cursorStyle = 'pointer'
  fabricObj.controls.mtr.render = setControlRender(size, elementRotate)
  const actionHandlerMtrClone = fabricObj.controls.mtr.actionHandler
  const actionHandlerTlClone = fabricObj.controls.tl.actionHandler
  fabricObj.controls.mtr.actionHandler = (
    eventData: MouseEvent,
    transformData: fabric.Transform,
    x: number,
    y: number
  ) => {
    return (
      actionHandlerMtrClone!(eventData, transformData, x, y) &&
      actionHandlerTlClone!(eventData, transformData, x, y)
    )
  }

  fabricObj.controls.customDeleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetX: 0,
    offsetY: 0,
    cursorStyle: 'pointer',
    mouseUpHandler: (_eventData, transform) => {
      console.log('customDeleteControl: mouseUpHandler')
      console.log(transform.target)
      if (transform.target.name === MARKER_TOOLS.ANGLE) {
        const targetGroup = transform.target as fabric.Group
        targetGroup.getObjects().forEach((v) => fb?.remove(v))
      }
      fb?.remove(transform.target)
      fb?.discardActiveObject()
      fb!.requestRenderAll()
      return false
    },
    render: setControlRender(size, elementRemove),
    sizeX: size,
    sizeY: size
  })
}

export const changeFabricSelection = (
  e: fabric.IEvent,
  fb: fabric.Canvas,
  elementRotate: HTMLImageElement,
  elementRemove: HTMLImageElement
) => {
  console.log('selection:updated', e)
  if (e?.selected?.length === 1) {
    const fabricObject = e.selected[0]
    setObjectEditable(fabricObject, true)
    fabricObject.borderColor = 'white'
    changeControlsForObject(fb, fabricObject, elementRotate, elementRemove)
    fabricObject.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      bl: false,
      br: false,
      tl: false,
      tr: false,
      mtr: fabricObject.name !== MARKER_TOOLS.RULER
    })
  }
}

export const drawText = (
  fb: fabric.Canvas,
  mouseFrom: Point,
  currentTextWidth: number,
  currentTextFontSize: number,
  currentColor: MARKER_COLORS
) => {
  const drawingTextbox = new fabric.Textbox('', {
    left: mouseFrom.x,
    top: mouseFrom.y,
    width: currentTextWidth,
    fontSize: currentTextFontSize,
    fill: currentColor,
    cursorColor: currentColor,
    stroke: currentColor,
    editingBorderColor: 'white',
    lineHeight: 1,
    // fontWeight: "bold",
    textAlign: 'left',
    splitByGrapheme: true,
    objectCaching: false
  })
  // const _this = this;
  // drawingTextbox.on('editing:entered', (e: fabric.IEvent) => {
  //   console.log('editing:entered')
  //   // console.log(e.target)
  //   // console.log(e.currentTarget)

  //   // drawingTextbox!._renderControls(fb, {
  //   //   hasControls: false
  //   // })
  // })
  drawingTextbox.on('editing:entered', () => {
    drawingTextbox.hiddenTextarea.setAttribute('maxlength', 200)
  })
  fb!.add(drawingTextbox)
  fb!.discardActiveObject()
  fb!.setActiveObject(drawingTextbox)
  fb!.requestRenderAll()
  drawingTextbox.enterEditing()
  // updateHistoryList()
  // drawingTextbox.hiddenTextarea!.focus();
  return drawingTextbox
}

export const getFabricItemByName = (fb: fabric.Canvas, name: string) => {
  let object = null
  const objects = fb.getObjects()
  for (let i = 0, len = fb.size(); i < len; i++) {
    if (objects[i].name && objects[i].name === name) {
      object = objects[i]
      break
    }
  }
  return object
}

const drawArrow = (
  fromX: number,
  fromY: number,
  toX: number,
  toY: number,
  theta: number,
  headlen: number
) => {
  const angle = (Math.atan2(fromY - toY, fromX - toX) * 180) / Math.PI
  const angle1 = ((angle + theta) * Math.PI) / 180
  const angle2 = ((angle - theta) * Math.PI) / 180
  const topX = headlen * Math.cos(angle1)
  const topY = headlen * Math.sin(angle1)
  const botX = headlen * Math.cos(angle2)
  const botY = headlen * Math.sin(angle2)
  let arrowX = fromX - topX
  let arrowY = fromY - topY
  let path = ''
  path = 'M ' + fromX + ' ' + fromY
  path += ' L ' + toX + ' ' + toY
  arrowX = toX + topX
  arrowY = toY + topY
  path += ' M ' + arrowX + ' ' + arrowY
  path += ' L ' + toX + ' ' + toY
  arrowX = toX + botX
  arrowY = toY + botY
  path += ' L ' + arrowX + ' ' + arrowY
  // console.log("path:", path);
  return path
}

export const drawing = (
  fb: fabric.Canvas,
  currentTool: MARKER_TOOLS,
  mouseFrom: Point,
  mouseTo: Point,
  currentColor: MARKER_COLORS,
  currentLineWidth: number,
  zoom: number
) => {
  // console.log('drawing')
  let fabricObject = null
  switch (currentTool) {
    case MARKER_TOOLS.LINE:
      fabricObject = new fabric.Line([
        mouseFrom.x,
        mouseFrom.y,
        mouseTo.x,
        mouseTo.y
      ])
      fabricObject.set({
        stroke: currentColor,
        strokeWidth: currentLineWidth
      })
      break
    case MARKER_TOOLS.ARROW:
      fabricObject = new fabric.Path(
        drawArrow(mouseFrom.x, mouseFrom.y, mouseTo.x, mouseTo.y, 22, 22)
      )
      fabricObject.set({
        stroke: currentColor,
        strokeWidth: currentLineWidth,
        fill: currentColor
      })
      break
    case MARKER_TOOLS.CIRCLE:
      fabricObject = new fabric.Ellipse({
        top: mouseFrom.y,
        left: mouseFrom.x,
        originX: mouseTo.x - mouseFrom.x > 0 ? 'left' : 'right',
        originY: mouseTo.y - mouseFrom.y > 0 ? 'top' : 'bottom',
        rx: Math.abs(mouseFrom.x - mouseTo.x) / 2,
        ry: Math.abs(mouseFrom.y - mouseTo.y) / 2
      })
      fabricObject.set({
        stroke: currentColor,
        strokeWidth: currentLineWidth,
        fill: 'rgba(255,255,255,0)'
      })
      break
    case MARKER_TOOLS.TRIANGLE: {
      // console.log("TOOLS.TRIANGLE:")
      const absX = Math.abs(mouseTo.x - mouseFrom.x)
      const absY = Math.abs(mouseTo.y - mouseFrom.y)
      const height = Math.min(absX, absY)
      fabricObject = new fabric.Triangle({
        top: mouseFrom.y,
        left: mouseFrom.x,
        originX: mouseTo.x - mouseFrom.x > 0 ? 'left' : 'right',
        originY: mouseTo.y - mouseFrom.y > 0 ? 'top' : 'bottom',
        width: Math.sqrt(Math.pow(height, 2) + Math.pow(height / 2.0, 2)),
        height
      })
      fabricObject.set({
        stroke: currentColor,
        strokeWidth: currentLineWidth,
        fill: 'rgba(255,255,255,0)'
      })
      break
    }
    case MARKER_TOOLS.RECTANGLE:
      fabricObject = new fabric.Rect({
        top: Math.min(mouseFrom.y, mouseTo.y),
        left: Math.min(mouseFrom.x, mouseTo.x),
        width: Math.abs(mouseFrom.x - mouseTo.x),
        height: Math.abs(mouseFrom.y - mouseTo.y)
      })
      fabricObject.set({
        stroke: currentColor,
        strokeWidth: currentLineWidth,
        fill: 'rgba(255,255,255,0)'
      })
      break
    case MARKER_TOOLS.RULER: {
      const radius = 4
      const lineWidth = 2
      const fabricLineHorizontal = new fabric.Line(
        [mouseTo.x - LineLength, mouseTo.y, mouseTo.x + LineLength, mouseTo.y],
        {
          stroke: '#FFFFFF',
          strokeWidth: lineWidth,
          strokeDashArray: [4, 4],
          strokeLineCap: 'round'
        }
      )
      const fabricObjectVertical = new fabric.Line(
        [mouseTo.x, mouseTo.y - LineLength, mouseTo.x, mouseTo.y + LineLength],
        {
          stroke: '#FFFFFF',
          strokeWidth: lineWidth,
          strokeDashArray: [4, 4],
          strokeLineCap: 'round'
        }
      )

      const fabricCircle = new fabric.Circle({
        left: mouseTo.x - radius + lineWidth / 2,
        top: mouseTo.y - radius + lineWidth / 2,
        radius,
        strokeWidth: 0,
        fill: '#FF003B'
      })
      fabricObject = new fabric.Group(
        [fabricLineHorizontal, fabricObjectVertical, fabricCircle],
        {
          originX: 'center',
          originY: 'center',
          name: MARKER_TOOLS.RULER,
          padding: (-LineLength + 28) * zoom
        }
      )
      break
    }
    case MARKER_TOOLS.ANGLE: {
      const objStep1 = getFabricItemByName(fb!, 'AngleStep1')
      if (objStep1 === null) {
        fabricObject = new fabric.Line([
          mouseFrom.x,
          mouseFrom.y,
          mouseTo.x,
          mouseTo.y
        ])

        fabricObject.set({
          name: 'AngleStep1',
          data: {
            from: { x: mouseFrom.x, y: mouseFrom.y },
            to: { x: mouseTo.x, y: mouseTo.y }
          },
          stroke: currentColor,
          strokeWidth: currentLineWidth
        })
      } else {
        const fabricObjectLine = new fabric.Line([
          objStep1.data.from.x,
          objStep1.data.from.y,
          mouseTo.x,
          mouseTo.y
        ])
        fabricObjectLine.set({
          stroke: currentColor,
          strokeWidth: currentLineWidth
        })

        const radius = 30
        const radiusText = 60
        const widthText = 30

        const angleStep1 =
          (Math.atan2(
            objStep1.data.to.y - objStep1.data.from.y,
            objStep1.data.to.x - objStep1.data.from.x
          ) *
            180) /
          Math.PI
        const angleStep2 =
          (Math.atan2(
            mouseTo.y - objStep1.data.from.y,
            mouseTo.x - objStep1.data.from.x
          ) *
            180) /
          Math.PI

        const angleStep1Calc = angleStep1 > 0 ? angleStep1 - 360 : angleStep1
        const angleStep2Calc = angleStep2 > 0 ? angleStep2 - 360 : angleStep2
        let angleBetweenReal = angleStep2Calc - angleStep1Calc
        if (angleStep1Calc > angleStep2Calc) {
          angleBetweenReal = angleStep2Calc - angleStep1Calc
        } else {
          angleBetweenReal = angleStep2Calc - angleStep1Calc - 360
        }

        const angleBetweenLogicForTextPos =
          angleBetweenReal < -180
            ? (angleBetweenReal + 360) / 2 + angleStep1Calc
            : (angleBetweenReal < -180
                ? angleBetweenReal + 180
                : angleBetweenReal) /
                2 +
              angleStep1Calc
        const xText =
          objStep1.data.from.x +
          Math.cos((angleBetweenLogicForTextPos * Math.PI) / 180) * radiusText
        const yText =
          objStep1.data.from.y +
          Math.sin((angleBetweenLogicForTextPos * Math.PI) / 180) * radiusText
        const angleText = `${Math.abs(
          angleBetweenReal < -180 ? 360 + angleBetweenReal : angleBetweenReal
        ).toFixed(0)}°`

        const fabricObjectArc = new fabric.Circle({
          left: objStep1.data.from.x - radius,
          top: objStep1.data.from.y - radius,
          radius,
          angle: 0,
          startAngle: angleBetweenReal > -180 ? angleStep2 : angleStep1,
          endAngle: angleBetweenReal > -180 ? angleStep1 : angleStep2,
          strokeWidth: currentLineWidth,
          stroke: currentColor,
          fill: 'rgba(255,255,255,0)'
        })

        const fabricObjectTextbox = new fabric.Textbox(angleText, {
          originX: 'center',
          originY: 'center',
          left: xText,
          top: yText,
          width: widthText,
          fontSize: 12,
          // backgroundColor: '#0000004D',
          fontFamily: 'Hiragino Sans',
          fontWeight: '300',
          // stroke: 'white',
          stroke: currentColor,
          textAlign: 'center',
          editable: false
        })

        fabricObject = new fabric.Group(
          [fabricObjectTextbox, fabricObjectLine, fabricObjectArc],
          {
            name: 'AngleStep2'
          }
        )
      }
      break
    }
  }
  return fabricObject
}

export const setAllObjectsEditable = (
  canvas: fabric.Canvas,
  editable: boolean
) => {
  const objects = canvas.getObjects()
  for (let i = 0, len = canvas.size(); i < len; i++) {
    setObjectEditable(objects[i], editable)
  }
}

export const setAllObjectsEditDisable = (canvas: fabric.Canvas) => {
  const objects = canvas.getObjects()
  for (let i = 0, len = canvas.size(); i < len; i++) {
    setObjectEditDisable(objects[i])
  }
  canvas.discardActiveObject()
  canvas.requestRenderAll()
}

export const refreshRulerPadding = (fb: fabric.Canvas, zoom: number) => {
  const objRulerGroup = getFabricItemByName(fb!, MARKER_TOOLS.RULER)
  objRulerGroup?.set('padding', (-LineLength + 28) * zoom)
}
